/*************************************************************************
 * @file my-likes.tsx
 * @author End Quote
 * @desc Page component for displaying user's liked items.
 *
 * @copyright (c) 2024 MVSSIVE. All rights reserved.
 *************************************************************************/

/* LOCAL IMPORTS */

const MyLikesPage = () => {};

export default MyLikesPage;
